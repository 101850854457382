import {useCreate, useCustom, useList, useTranslate, useUpdate} from '@pankod/refine-core';
import React, {useEffect, useRef, useState} from 'react';
import { Trans } from "react-i18next";
import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Select,
    Image
} from '@pankod/refine-antd';
import { Input as AntInput } from "antd";
import {useLocation, useNavigate} from 'react-router-dom';
import Autocomplete, {usePlacesWidget} from 'react-google-autocomplete';
import {API_URL} from '../../constants';
import {apiFunction} from '../../apiFuntion';
const { Option } = Select;
const { Text, Title } = Typography;

export interface IRegisterForm {
    id: number;
    email: string;
    nickname: string;
    sesso: string;
    nome: string;
    cognome: string;
    dataNascita: string;
    telefono: string;
    citta: string;
    password: string;
    confirmed: boolean;
    ragioneSociale: string;
    insegnaBar: string;
    indirizzo: string;
    cap: number;
    comune: string;
    provincia: string;
    nazione: string;
    nomeContatto: string;
    latitudine: string;
    longitudine: string;
    friend: string;
}

type LocationState = { token: string; id: number };

export const RegisterPage: React.FC = () => {
    const [form] = Form.useForm<IRegisterForm>();
    const navigate = useNavigate();
    const location = useLocation();
    const tokenGift = decodeURIComponent(location.search)?.split('?')[1]?.split('&')[0].split('=')[1];
    const id = decodeURIComponent(location.search)?.split('?')[1]?.split('&')[1]?.split('=')[1];
    const t = useTranslate();
    const apiImage = API_URL;
    const [tipologia, setTipologia] = useState('lover');
    const { mutate: register, isSuccess, isLoading, data } = useCreate<IRegisterForm>();

    // const userMovimentazioni = useList<IUserMovimentazioni>({
    //     resource: 'user-movimentazionis',
    //     metaData: {
    //         populate: "user_gift, user_gift.prodotti, users",
    //     },
    //     config: {
    //         filters: [
    //             {
    //                 field: "token",
    //                 operator: "eq",
    //                 value: token
    //             }
    //         ]
    //     },
    // });

    // const sendEmail = useCustom<IEmailConfirm>({
    //     url: `${API_URL}/api/auth/send-email-confirmation`,
    //     method: "post",
    //     config: {
    //         payload: {
    //             email: data?.data.email
    //         }
    //     }
    // })
    let antInputRef = useRef(null);
    const inputRef = useRef(null);
    let { ref: antRef } = usePlacesWidget({
        apiKey: 'AIzaSyDmu4vnp3NLZyvp1sfMwfJamEk0y8Uk1t0',
        onPlaceSelected: (place) => {
            //@ts-ignore
            antInputRef.current = place?.formatted_address;
        },
    });

    function genUsername(usr: any) {
        var text = "";
        var possible = usr;

        for (let i = 0; i < 10; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    const onSubmit = (values: any) => {
        values.confirmed = (tipologia === 'lover');
        values.tipologia = tipologia;
        values.role = 1;
        values.frontEndRole = 'user';
        values.isFirstLogin = true;
        //values.friend = id;
        values.username = genUsername(values.email);
        register({
            resource: "users",
            errorNotification: (data: any, values: any, resource) => {
                // console.log(data.email, values.email)
                return {
                    message: `Email già registrata: ${values.email}`,
                    description: "Error",
                    type: "error",
                };
            },
            values
        });

    }

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };



    useEffect(() => {
        if (isSuccess && !isLoading) {
                console.log(data);
            if (tipologia !== 'lover') {
                //sendEmail.refetch();
            }
            navigate('/success' + ((location.search) ? '?token=' + tokenGift + '&id=' + id : ''))
        }
        const queryParams = new URLSearchParams(location.search);
        const singleValue = queryParams.get('type');
        if (!singleValue) return
        setTipologia(singleValue);
    });

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
        },
    };

    return (

        <>
            {/*<Top>*/}
            <AntdLayout
                style={{
                    background: '#c74141'
                }}
            >
                <Row>
                    <Col style={{textAlign: 'center'}}>
                        <Form<IRegisterForm>
                            {...formItemLayout}
                            form={form}
                            onError={goToTop}
                            onFinish={onSubmit}
                            requiredMark={false}
                            initialValues={{
                                remember: false,
                                prefix: '39',
                            }}
                            style={{
                                backgroundColor: '#C64141'
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Image
                                        style={{marginBottom: 26, marginTop: 10}}
                                        src="/images/logo.png"
                                        width="50%"
                                        preview={false}
                                    />
                                    <Title level={4} style={{color: '#FFFFFF', marginTop: '20px'}}>
                                        Utente {((tipologia === 'bar') ? ' Bar': 'Lover')}
                                    </Title>
                                    <Trans
                                        i18nKey="pages.register.message"
                                        defaults="<0>Lorem Ipsum il </0> tuo account"
                                        components={[<Text key="0" style={{color: '#67be23'}}/>]}
                                    />
                                </Col>
                            </Row>

                            <Row justify={'center'} align={'top'}>
                                <Col span={24} style={{padding: '20px'}}>
                                    <Title level={5} style={{marginTop: '20px', textAlign: 'left', color: '#ffffff'}}>
                                        Registrazione
                                    </Title>
                                    <Card bordered={true}
                                          style={{
                                              textAlign: 'left',
                                              borderRadius: '20px',
                                              width: '100%',
                                              background: 'rgba(147,41,42,0)',
                                              color: '#FFFFFF'
                                          }}>
                                        <Form.Item
                                            name="email"
                                            // label={t("pages.register.email", "Email")}
                                            rules={[{required: true}]}
                                        >
                                            <Input
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                placeholder="Email"
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={true}
                                            name="username"
                                            // label={t("pages.register.username", "Username")}
                                            rules={[{required: false, message: 'Username Richiesta'}]}
                                        >
                                            <Input
                                                   placeholder="Username"
                                                   bordered={false}
                                                   style={{
                                                       color: '#FFFFFF',
                                                       borderRadius: '0px',
                                                       textAlign: 'left',
                                                       border: 'none',
                                                       borderBottom: '1px solid #FFFFFF',
                                                       padding: '5px 20px',
                                                       width: '100%'
                                                   }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="password"
                                            // label="Password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Inserisci la Password! - Minimo 8 caratteri',
                                                    min: 8,
                                                },
                                            ]}
                                            hasFeedback

                                        >
                                            <Input.Password
                                                placeholder={'Password'}
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="confirm"
                                            // label="Confirm Password"
                                            dependencies={['password']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Conferma la tua Passsword!',
                                                },
                                                ({getFieldValue}) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Le passowrd inserite non conicidono!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                placeholder={'Confirm Password'}/>
                                        </Form.Item>
                                        {/*<Form.Item hidden={(tipologia === 'bar')}*/}
                                        {/*           name="sesso"*/}
                                        {/*    // label={t(*/}
                                        {/*    //     "pages.register.sesso",*/}
                                        {/*    //     "Sesso",*/}
                                        {/*    // )}*/}
                                        {/*           rules={[{required: false}]}*/}
                                        {/*>*/}
                                        {/*    <Select bordered={false} placeholder="Seleziona" style={{*/}
                                        {/*        color: '#FFFFFF',*/}
                                        {/*        borderRadius: '0px',*/}
                                        {/*        textAlign: 'left',*/}
                                        {/*        border: 'none',*/}
                                        {/*        borderBottom: '1px solid #FFFFFF',*/}
                                        {/*        width: '100%'*/}
                                        {/*    }}>*/}
                                        {/*        <Option value="Seleziona">Sesso</Option>*/}
                                        {/*        <Option value="Maschio">Maschio</Option>*/}
                                        {/*        <Option value="Femmina">Femmina</Option>*/}
                                        {/*    </Select>*/}
                                        {/*</Form.Item>*/}
                                        <Form.Item hidden={(false)}
                                                   name="nickname"
                                            // label={t(
                                            //     "pages.register.nickname",
                                            //     "Nickname",
                                            // )}
                                                   rules={[{required: false}]}
                                        >
                                            <Input
                                                type="nickname"
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                placeholder={(tipologia === 'lover') ? "Nickname" : "Nome Bar"}
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={(tipologia === 'lover')}
                                                   name="ragioneSociale"
                                            // label={t(
                                            //     "pages.register.ragioneSociale",
                                            //     "Ragione Sociale",
                                            // )}
                                                   rules={[{required: (tipologia === 'bar')}]}
                                        >
                                            <Input
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                type="ragioneSociale"
                                                placeholder="Ragione Sociale"
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={(tipologia === 'lover')}
                                                   name="insegnaBar"
                                            // label={t(
                                            //     "pages.register.insegnaBar",
                                            //     "Insegna Bar",
                                            // )}
                                                   rules={[{required: (tipologia === 'bar')}]}
                                        >
                                            <Input
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                type="insegnaBar"
                                                placeholder="Insegna Bar"
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={(tipologia === 'bar')}
                                                   name="nome"
                                            // label={t(
                                            //     "pages.register.nome",
                                            //     "Nome",
                                            // )}
                                                   rules={[{required: (tipologia === 'lover')}]}
                                        >
                                            <Input
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                type="nome"
                                                placeholder="Nome"
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={(tipologia === 'bar')}
                                                   name="cognome"
                                            // label={t(
                                            //     "pages.register.cognome",
                                            //     "Cognome",
                                            // )}
                                                   rules={[{required: (tipologia === 'lover')}]}
                                        >
                                            <Input
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                type="cognome"
                                                placeholder="Cognome"
                                            />
                                        </Form.Item>
                                        {/*<Form.Item hidden={(tipologia === 'bar')}*/}
                                        {/*           name="dataNascita"*/}
                                        {/*    // label={t(*/}
                                        {/*    //     "pages.register.dataNascita",*/}
                                        {/*    //     "Data Di Nascita",*/}
                                        {/*    // )}*/}
                                        {/*           rules={[{required: (tipologia === 'lover')}]}*/}
                                        {/*>*/}
                                        {/*    <Input bordered={false} type={'date'} style={{*/}
                                        {/*        color: '#FFFFFF',*/}
                                        {/*        borderRadius: '0px',*/}
                                        {/*        textAlign: 'left',*/}
                                        {/*        border: 'none',*/}
                                        {/*        borderBottom: '1px solid #FFFFFF',*/}
                                        {/*        width: '100%'*/}
                                        {/*    }}/>*/}
                                        {/*</Form.Item>*/}
                                        <Autocomplete hidden={(tipologia === 'lover')}
                                            apiKey={'AIzaSyDmu4vnp3NLZyvp1sfMwfJamEk0y8Uk1t0'}
                                            onPlaceSelected={(place) => {
                                                let postal_code = place.address_components.find((value: any, index: any) => {
                                                    return value.types.some((v: any) => v === 'postal_code' );
                                                });
                                                let indirizzo = place.address_components.find((value: any, index: any) => {
                                                    return value.types.some((v: any) => v === 'route' );
                                                });
                                                let comune = place.address_components.find((value: any, index: any) => {
                                                    return value.types.some((v: any) => v === 'administrative_area_level_3' );
                                                });
                                                let provincia = place.address_components.find((value: any, index: any) => {
                                                    return value.types.some((v: any) => v === 'administrative_area_level_2' );
                                                });
                                                let nazione = place.address_components.find((value: any, index: any) => {
                                                    return value.types.some((v: any) => v === 'country' );
                                                });
                                                form.setFieldsValue({
                                                    'indirizzo': indirizzo.long_name,
                                                    'cap': postal_code.long_name,
                                                    'comune': comune.long_name,
                                                    'citta': comune.long_name,
                                                    'provincia': provincia.short_name,
                                                    'nazione': nazione.long_name,
                                                    'latitudine': place.geometry.location.lat().toString(),
                                                    'longitudine': place.geometry.location.lng().toString()
                                                })
                                            }}
                                            options={{
                                                types: ["address"],
                                                componentRestrictions: { country: "it" },
                                            }}
                                            style={{
                                                marginBottom: '20px',
                                                background: '#ffea7f',
                                                color: '#000000',
                                                borderRadius: '0px',
                                                textAlign: 'left',
                                                border: 'none',
                                                borderBottom: '1px solid #FFFFFF',
                                                width: '100%'
                                            }}
                                        />
                                        {/*<AntInput hidden={(tipologia === 'lover')}*/}
                                        {/*    ref={(c) => {*/}
                                        {/*        // @ts-ignore*/}
                                        {/*        antInputRef.current = c;*/}
                                        {/*        if (c) {*/}
                                        {/*            // @ts-ignore*/}
                                        {/*            antRef['current'] = c.input;*/}
                                        {/*        }*/}

                                        {/*    }}*/}
                                        {/*    style={{*/}
                                        {/*        marginBottom: '20px',*/}
                                        {/*        background: '#ff7f7f00',*/}
                                        {/*        color: '#FFFFFF',*/}
                                        {/*        borderRadius: '0px',*/}
                                        {/*        textAlign: 'left',*/}
                                        {/*        border: 'none',*/}
                                        {/*        borderBottom: '1px solid #FFFFFF',*/}
                                        {/*        width: '100%'*/}
                                        {/*    }}/>*/}
                                        <Form.Item hidden={(true)}
                                                   name="latitudine"
                                                   rules={[{required: false}]}
                                        >
                                            <Input
                                                type="latitudine"
                                                bordered={false}
                                                placeholder="Latitudine"
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={(true)}
                                                   name="longitudine"
                                                   rules={[{required: false}]}
                                        >
                                            <Input
                                                type="longitudine"
                                                bordered={false}
                                                placeholder="Longitudine"
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={(tipologia === 'lover')}
                                                   name="indirizzo"
                                            // label={t(
                                            //     "pages.register.indirizzo",
                                            //     "Indirizzo",
                                            // )}
                                                   rules={[{required: (tipologia === 'bar')}]}
                                        >
                                            <Input
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                type="indirizzo"
                                                placeholder="Indirizzo"
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={(tipologia === 'lover')}
                                                   name="cap"
                                            // label={t(
                                            //     "pages.register.cap",
                                            //     "CAP",
                                            // )}
                                                   rules={[{required: (tipologia === 'bar')}]}
                                        >
                                            <Input
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                type="cap"
                                                placeholder="CAP"
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={(tipologia === 'lover')}
                                                   name="comune"
                                            // label={t(
                                            //     "pages.register.comune",
                                            //     "Comune",
                                            // )}
                                                   rules={[{required: (tipologia === 'bar')}]}
                                        >
                                            <Input
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                type="comune"
                                                placeholder="Comune"
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={(tipologia === 'lover')}
                                                   name="provincia"
                                            // label={t(
                                            //     "pages.register.provincia",
                                            //     "Provincia",
                                            // )}
                                                   rules={[{required: (tipologia === 'bar')}]}
                                        >
                                            <Input
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                type="provincia"
                                                placeholder="Provincia"
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={(tipologia === 'lover')}
                                                   name="nazione"
                                            // label={t(
                                            //     "pages.register.nazione",
                                            //     "Nazione",
                                            // )}
                                                   rules={[{required: (tipologia === 'bar')}]}
                                        >
                                            <Input
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                type="nazione"
                                                placeholder="Nazione"
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={(tipologia === 'lover')}
                                            name="telefono"
                                            // label={t(
                                            //     "pages.register.telefono",
                                            //     "Telefono",
                                            // )}
                                                   rules={[{required: (tipologia === 'bar')}]}
                                        >
                                            <Input
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                // addonBefore={prefixSelector}
                                                type="telefono"
                                                placeholder="Telefono"
                                            />
                                        </Form.Item>
                                        <Form.Item hidden={(tipologia === 'lover')}
                                                   name="nomeContatto"
                                            // label={t(
                                            //     "pages.register.nomeContatto",
                                            //     "Nome Contatto",
                                            // )}
                                                   rules={[{required: (tipologia === 'bar')}]}
                                        >
                                            <Input
                                                bordered={false}
                                                style={{
                                                    color: '#FFFFFF',
                                                    borderRadius: '0px',
                                                    textAlign: 'left',
                                                    border: 'none',
                                                    borderBottom: '1px solid #FFFFFF',
                                                    padding: '5px 20px',
                                                    width: '100%'
                                                }}
                                                type="nomeContatto"
                                                placeholder="Nome Contatto"
                                            />
                                        </Form.Item>
                                        {/*<Form.Item hidden={(tipologia === 'bar')}*/}
                                        {/*           name="citta"*/}
                                        {/*    // label={t(*/}
                                        {/*    //     "pages.register.citta",*/}
                                        {/*    //     "Città",*/}
                                        {/*    // )}*/}
                                        {/*           rules={[{required: (tipologia === 'lover')}]}*/}
                                        {/*>*/}
                                        {/*    <Input*/}
                                        {/*        bordered={false}*/}
                                        {/*        style={{*/}
                                        {/*            color: '#FFFFFF',*/}
                                        {/*            borderRadius: '0px',*/}
                                        {/*            textAlign: 'left',*/}
                                        {/*            border: 'none',*/}
                                        {/*            borderBottom: '1px solid #FFFFFF',*/}
                                        {/*            padding: '5px 20px',*/}
                                        {/*            width: '100%'*/}
                                        {/*        }}*/}
                                        {/*        type="citta"*/}
                                        {/*        placeholder="Città"*/}
                                        {/*    />*/}
                                        {/*</Form.Item>*/}
                                    </Card>
                                </Col>
                            </Row>
                            <Row justify={'center'} align={'top'}>
                                <Col span={24} style={{padding: '10px'}}>
                                    <Title level={5} style={{marginTop: '20px', textAlign: 'left', color: '#ffffff'}}>
                                        Termini e Condizioni
                                    </Title>
                                    <Card bordered={true}
                                          style={{
                                              textAlign: 'justify',
                                              borderRadius: '20px',
                                              width: '100%',
                                              background: 'rgba(147,41,42,0)',
                                              color: '#FFFFFF'
                                          }}>
                                        <Trans
                                            i18nKey="pages.register.message"
                                            defaults="<0>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</0>"
                                            components={[<Text key="0" style={{color: '#ffffff', fontSize: '10px'}}/>]}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                            <Row justify={'center'} align={'top'}>
                                <Col span={24} style={{padding: '10px'}}>
                                    <Title level={5} style={{marginTop: '20px', textAlign: 'left', color: '#ffffff'}}>
                                        Privacy
                                    </Title>
                                    <Card bordered={true}
                                          style={{
                                              textAlign: 'justify',
                                              borderRadius: '20px',
                                              width: '100%',
                                              background: 'rgba(147,41,42,0)',
                                              color: '#FFFFFF'
                                          }}>
                                        <Trans
                                            i18nKey="pages.register.message"
                                            defaults="<0>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</0>"
                                            components={[<Text key="0" style={{color: '#ffffff', fontSize: '10px'}}/>]}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                            <Row justify={'center'} align={'top'}>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType={'submit'}
                                                style={{
                                                    color: '#000000',
                                                    background: '#fad1b7',
                                                    borderRadius: '30px',
                                                    width: '160px',
                                                    height: '40px'
                                                }}
                                                // onClick={goToTop}
                                        >Conferma</Button>
                                    </Form.Item>
                                </Col>
                                <div style={{marginTop: 8}}>
                                    <Text style={{fontSize: 12}}>
                                        {t(
                                            'pages.register.back',
                                            'Back to',
                                        )}{' '}
                                        <a href="/home" style={{fontWeight: 'bold'}}>
                                            {t('pages.register.home', 'Home')}
                                        </a>
                                    </Text>
                                </div>

                            </Row>
                        </Form>
                    </Col>
                </Row>
            </AntdLayout>
            {/*</Top>*/}
        </>

    );
};
