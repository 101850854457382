import {
    IResourceComponentsProps, useCreate, useUpdate,
} from '@pankod/refine-core';
import {
    Typography,
    Card,
    Row,
    Col,
    Button, Form, Input, Avatar,
} from '@pankod/refine-antd';
import {
    CreditCardFilled,
    FlagOutlined,
    LinkOutlined,
    CreditCardOutlined,
    EditFilled,
    ShoppingCartOutlined
} from '@ant-design/icons';
import QRCode from "qrcode.react";
import {useLocation, useNavigate} from 'react-router-dom';
import {SITE_URL, TOKEN_KEY} from '../../constants';
import jwt_decode from 'jwt-decode';
import React from 'react';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const { Title } = Typography;
type LocationState = { qrCode: string};

export const QrList: React.FC<IResourceComponentsProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {qrCode} = location.state as LocationState;

    console.log(location)
    return (
        <>
            <Row justify={'center'} align={'top'} style={{marginTop: 40, justifyContent: 'center'}}>
                <Col span={24}>
                        {/*<FontAwesomeIcon icon={solid('qrcode')}*/}
                        {/*                 style={{*/}
                        {/*                     boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                        {/*                     width: 80,*/}
                        {/*                     height: 80,*/}
                        {/*                     border: '1px solid #FFFFFF',*/}
                        {/*                     borderRadius: '50%',*/}
                        {/*                     background: '#FAD1B7',*/}
                        {/*                     color: '#C64141',*/}
                        {/*                     zIndex: '99999',*/}
                        {/*                     padding: 15,*/}
                        {/*                     // marginLeft: '40%',*/}
                        {/*                     fontSize: 20,*/}
                        {/*                 }}/>*/}
                    {/*<Card*/}
                    {/*    style={{*/}
                    {/*        textAlign: 'center',*/}
                    {/*        border: 'none',*/}
                    {/*        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                    {/*        borderRadius: '40px',*/}
                    {/*        height: 280,*/}
                    {/*        width: '100%',*/}
                    {/*        background: '#e28a74',*/}
                    {/*        color: '#FFFFFF' }}>*/}
                    {/*    <Row gutter={[16, 32]} style={{marginBottom: '10px'}}>*/}
                    {/*        <Col span={24}>*/}
                    {/*            <Title level={5} style={{color: '#FFFFFF'}}>Animazione</Title>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</Card>*/}
                </Col>
            </Row>
            <Row justify={'center'} align={'top'} style={{marginTop: 0, textAlign: 'center'}}>
                {/*<Col span={24}>*/}
                    {/*<div style={{*/}
                    {/*    position: 'absolute',*/}
                    {/*    width: 80,*/}
                    {/*    height: 80,*/}
                    {/*    border: '2px solid #FFFFFF',*/}
                    {/*    borderRadius: '50%',*/}
                    {/*    background: '#e28a74',*/}
                    {/*    zIndex: '99999',*/}
                    {/*    padding: 5,*/}
                    {/*    marginLeft: '40%',*/}
                    {/*    marginTop: -25*/}
                    {/*}}>*/}
                    {/*    <LinkOutlined style={{fontSize: 30, verticalAlign: 'middle', padding: 20, color: '#FFFFFF'}}/>*/}
                    {/*</div>*/}
                    {/*<Title level={3} style={{color: '#932929', marginTop: 25}}>Usa</Title>*/}
                {/*</Col>*/}
            </Row>

            <Row justify={'center'} align={'top'} style={{marginTop: 1, justifyContent: 'center'}}>
                <Col span={24} style={{ padding: '20px' }}>
                    {/*<Card*/}
                    {/*    style={{*/}
                    {/*        textAlign: 'center',*/}
                    {/*        border: 'none',*/}
                    {/*        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',*/}
                    {/*        borderRadius: '40px',*/}
                    {/*        height: 230,*/}
                    {/*        width: '100%',*/}
                    {/*        background: '#e28a74',*/}
                    {/*        color: '#FFFFFF' }}>*/}
                    {/*    <Row gutter={[16, 32]} style={{marginBottom: '10px', justifyContent: 'center'}}>*/}
                    {/*        <Col span={22}>*/}
                    {/*            <Title level={3} style={{color: '#800000', marginTop: 25}}>Ecco il tuo QrCode!</Title>*/}
                    {/*            <Col>*/}
                    {/*                <QRCode id="qr-gen"*/}
                    {/*                        value={qrCode}*/}
                    {/*                        size={120}*/}
                    {/*                        level={"H"}*/}
                    {/*                        includeMargin={true} />*/}
                    {/*                <br />*/}
                    {/*                {qrCode}*/}
                    {/*            </Col>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</Card>*/}
                    <span style={{
                        color: '#93292AFF',
                        fontSize: 18
                    }}>
                        Avvicina il telefono per far scannerizzare il Qr code dal Barista. Una volta scansionato, goditi il tuo Caffe!
                    </span>
                </Col>
                <Col span={24}>
                    <QRCode id="qr-gen"
                            value={SITE_URL + '/&code=' + qrCode}
                            size={220}
                            level={"H"}
                            includeMargin={true}
                            style={{
                                borderRadius: '20px',
                            }}
                    />
                </Col>
            </Row>
            <Row justify={'center'} align={'top'} style={{marginTop: 10, justifyContent: 'center'}}>
                <Col span={24} style={{ padding: 30 }}>
                  <span style={{
                      color: '#858585',
                      fontSize: 12,
                      textAlign: 'justify'
                  }}>
                        Se hai generato per sbaglio il QR codee e non sei ancora pronto a consumarlo, puoi uscire dalla schermata, lo ritroverai nella tua lista movimenti.
                    </span>
                </Col>
            </Row>
            <Row justify={'center'} align={'top'} style={{marginTop: 1, justifyContent: 'center'}}>
                <Col span={24} style={{ padding: '1px' }}>
                    <Button type="primary" htmlType={'submit'}
                            style={{marginTop: 45, background: '#93292a', borderRadius: '30px', width: '150px', height: '40px', border: 'none'}} onClick={() => navigate('/home')}
                    >Torna alla Home</Button>
                </Col>
            </Row>

        </>
    );
};
